<template>
  <main class="about">
    <Breadcrumb></Breadcrumb>

    <section class="">
      <PageHeader
        title="Changing Lives Through Discovery and Innovation"
        :video="require(`@/assets/video/research.mp4`)"
        :backgroundImage="require(`@/assets/img/research-poster.jpg`)"
        backgroundColor="transparent"
        textColor="white"
        overlay="medium"
        align="center"
        size="lg"
      >
        <p>
          The University of Georgia is a comprehensive research university focused on improving lives, advancing scientific understanding, and unlocking innovative solutions.
        </p>
      </PageHeader>
    </section>

    <Section type="full" imgSide="right" imgURL="research/36645-134.jpg">
      <h3>Our Research Commitments</h3>
      <p>
        From combating infectious diseases and creating a dependable food supply to strengthening cyber and global security, the University of Georgia is pursuing a better tomorrow by tackling the world’s greatest challenges.
      </p>
      <p>
        As one of the nation’s top universities for technology commercialization, we strive to ensure our discoveries benefit society.
      </p>
      <p>
        Through research, innovation, and interdisciplinary collaboration, we are improving the world through commitments to:
      </p>
      <ul class="row row-cols-1 row-cols-sm-3 g-3 list-unstyled d-flex justify-content-center align-items-center">
        <li class="col text-uppercase" style="max-width: 215px; font-family: Oswald;">
          <div style="aspect-ratio: 1/1">
            <div style="background: #ba0c2f" class="h-100 p-4 text-center text-white rounded-circle d-flex justify-content-center align-items-center">
              <span class="fs-4 fw-normal">Stronger Communities</span>
            </div>   
          </div>
        </li>
        <li class="col" style="max-width: 215px; font-family: Oswald; text-transform:uppercase; font-weight: 500;">
          <div style="aspect-ratio: 1/1">
            <div class="h-100 p-4 bg-grey text-center text-white rounded-circle d-flex justify-content-center align-items-center">
              <span class="fs-4">Healthier Lifestyles</span>
            </div>   
          </div>
        </li>
        <li class="col" style="max-width: 215px; font-family: Oswald; text-transform:uppercase; font-weight: 500;">
          <div style="aspect-ratio: 1/1">
            <div class="h-100 p-4 bg-black text-center text-white rounded-circle d-flex justify-content-center align-items-center">
              <span class="fs-4">A More Secure Future</span>
            </div>   
          </div>
        </li>
        <!-- <li class="col">
          <div class="card text-center bg-white border-light border-5 h-100">
            <div
              class="card-body d-flex flex-column justify-content-center align-items-center"
            >
              <span class="fs-4">Stronger Communities</span>
            </div>
          </div>
        </li>
        <li class="col">
          <div class="card text-center bg-white border-light border-5 h-100">
            <div
              class="card-body d-flex flex-column justify-content-center align-items-center"
            >
              <span class="fs-4">Healthier Lifestyles</span>
            </div>
          </div>
        </li>
        <li class="col">
          <div class="card text-center bg-white border-light border-5 h-100">
            <div
              class="card-body d-flex flex-column justify-content-center align-items-center"
            >
              <span class="fs-4">A More Secure Future</span>
            </div>
          </div>
        </li> -->
      </ul>
    </Section>

    <section class="row g-0">
      <div class="col-md-4">
        <Figure
          prefix=""
          :endAmount="65"
          :startAmount="0"
          :decimals="0"
          suffix="%"
          subheading="increase in NIH/NSF expenditures over 6 years"
          backgroundColor="red"
          textColor="white"
          :textured="false"
        ></Figure>
      </div>
      <div class="col-md-4">
        <Figure
          prefix="Top "
          :endAmount="5"
          :startAmount="0"
          subheading="for new products based on research for nearly a decade"
          backgroundColor="light"
          textColor="black"
          :textured="false"
        ></Figure>
      </div>
      <div class="col-md-4">
        <Figure
          prefix="$"
          :endAmount="571"
          :startAmount="0"
          suffix="M"
          subheading="R&amp;D Expenditures"
          citation="FY 2023"
          backgroundColor="black"
          textColor="white"
          :textured="false"
        ></Figure>
      </div>
    </section>

    <section>
      <div class="row g-0">
        <div class="col-12 col-md-6" style="min-height: 400px;">
          <VideoBackground
            :src="require('@/assets/video/researchers.mp4')"
            :poster="require('@/assets/img/research/marshall-shepherd.jpg')"
          />
        </div>
        <div class="col-12 col-md-6">
          <div class="d-flex flex-column justify-content-center h-100 p-5">
            <h3>Faculty Recognition</h3>
            <p>
              UGA faculty are recognized leaders in their fields. Some advise the White House, World Health Organization, and FDA on a variety of topics while developing research aimed at enhancing the quality of life for people worldwide.
            </p>
            <p>
              In the last five years, 12 UGA faculty have been inducted as fellows into the National Academy of Engineering, the National Academy of Sciences, and the National Academy of Inventors.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-geo bg-geo--dark row g-0">
      <div class="col-md-4">
        <Figure
          prefix=""
          suffix="+"
          :endAmount="170"
          :startAmount="0"
          subheading="AAAS Fellows"
          backgroundColor=""
          textColor="dark"
          :textured="false"
        ></Figure>
      </div>
      <div class="col-md-4">
        <Figure
          :endAmount="88"
          :startAmount="0"
          subheading="NSF CAREER Award Recipients"
          backgroundColor=""
          textColor="dark"
          :textured="false"
        ></Figure>
      </div>
      <div class="col-md-4">
        <Figure
          :endAmount="160"
          :startAmount="0"
          subheading="Faculty Fulbright Scholars since 1962"
          backgroundColor=""
          textColor="dark"
          :textured="false"
        ></Figure>
      </div>
    </section>

    <Section
      type="standard"
      imgSide="right"
      imgURL="research/3AT2315.jpg"
      color="bg-primary bg-diagonal--dark"
      class="text-white"
    >
      <h3>UGA School of Medicine</h3>
      <p>
        The University of Georgia is establishing a new School of Medicine that will leverage our robust statewide service network and our growing research enterprise to address the nation’s physician shortage and to build a healthier Georgia.
      </p>
      <Btn color="white" size="lg" outline href="https://medicine.uga.edu/"
        >Learn More<span class="visually-hidden">
          about UGA's School of Medicine</span
        ></Btn
      >
    </Section>
  </main>
</template>

<script>
// @ is an alias to /src
// import EmergencyBanner from '@/components/EmergencyBanner.vue';
import Btn from "@/components/Btn.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import PageHeader from "@/components/PageHeader.vue";
import Figure from "@/components/Figure.vue";
import Section from "@/components/Section.vue";
import VideoBackground from "@/components/VideoBackground.vue";

export default {
  name: "Research",
  metaInfo: {
    title: "Research at UGA",
    description:
      "The University of Georgia is committed to changing lives through discovery and innovation. Our comprehensive research is saving lives.",
    og: {
      image: "",
      title: "",
      description: "",
    },
  },
  methods: {},
  data() {
    return {};
  },
  components: {
    // EmergencyBanner,
    Btn,
    PageHeader,
    Breadcrumb,
    Figure,
    Section,
    VideoBackground,
  },
};
</script>

<style scoped lang="scss"></style>
